.resize-container {
    display: inline-block;
    width: 376px;
    height: 221px;
    margin-top: 33px;
  }
  .resize-div{
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }

  .round-button {
    position: absolute;
    min-width: 33px;
    min-height: 33px;
    border-radius: 50%;
    border:3px solid #e9e9e9;
    overflow:hidden;
    text-align: center;
    background: #959595 !important; 
  white-space: nowrap;     
    
    color: white;
    cursor: pointer;
  }
  
  .round-button-circle {
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
  }
  .round-button:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
  
  .round-button.reservado  {
    background-color: #dddddd !important;
    color: #a3a3a3;
    cursor:not-allowed;
  }
   .round-button.selecionado{
    background-color: #4caf50 !important;
    cursor:default;

  }
  .round-button:not(.minhaPosicao){
   
  }
  .round-button.minhaPosicao {
    cursor:default;
    color: #fff;
    background-color: inherit !important;
  }

  .round-button a {
    display:block;
    float:left;
    width:100%;
    padding-top:50%;
    padding-bottom:50%;
    line-height:1em;
    margin-top:-0.5em;

    text-align:center;
    color:#e2eaf3;
    font-family:Verdana;
    font-size:1.2em;
    font-weight:bold;
    text-decoration:none;
  }
  